import React from "react";
import { Link } from 'react-router-dom';
import "./Footer.css";
import CopyRight from "../CopyRight";
import Logo from "../Logo";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from "react-router";

const Footer = () => {
  function takeToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  let navigate = useNavigate();

  return (
    <div className="footer_container">
      <div className="footer_top_section">
        <div className="footer_top_left">
          <div className="footer_top_left_logo" >
            <Logo width={195} marginY={2} />
          </div>
          <div className="footer_top_icons_div">
            <a target="_blank"
              rel="noreferrer" style={{
                textDecoration: "none",
                color: "black"
              }} href="https://www.instagram.com/gebootcamp/">
              <div className="footer_top_icon"><InstagramIcon /></div>
            </a>
            <a target="_blank"
              rel="noreferrer" style={{
                textDecoration: "none",
                color: "black"
              }} href="https://www.facebook.com/gebootcamp">
              <div className="footer_top_icon"><FacebookIcon /></div>
            </a>
            <a target="_blank"
              rel="noreferrer" style={{
                textDecoration: "none",
                color: "black"
              }} href="https://twitter.com/i/flow/login?redirect_after_login=%2Fgebootcamp">
              <div className="footer_top_icon"><TwitterIcon /></div>
            </a>
            <a target="_blank"
              rel="noreferrer" style={{
                textDecoration: "none",
                color: "black"
              }} href="https://www.linkedin.com/company/gebootcamp/">
              <div className="footer_top_icon"><LinkedInIcon /></div>
            </a>
            <a target="_blank"
              rel="noreferrer" style={{
                textDecoration: "none",
                color: "black"
              }} href="https://www.youtube.com/channel/UCXvjVrvhDV-_304hx4iwFrA">
              <div className="footer_top_icon"><YouTubeIcon /></div>
            </a>
          </div>
        </div>
        <div className="footer_vertical_line hide" />
        <div className="footer_top_right">
          <div className="footer_top_title">Don't Miss The Opportunity</div>
          <div>
            <Link to="/applyNow" className="footer_top_btn primary_btn" onClick={takeToTop}>
              Apply Now
            </Link>
          </div>
        </div>
      </div>
      <div className="footer_bottom">
        <div className="footer_left_div">
          <div className="footer_GEB_section footer_section" >
            <div className="footer_title">
              GEB
            </div>
            <div className="footer_items footer_left_items">
              <Link to="/" onClick={takeToTop}>Home</Link>
            </div>
            <div className="footer_items footer_left_items">
              <Link to="/aboutGeb" onClick={takeToTop}>About</Link>
            </div>
            <div className="footer_items footer_left_items">
              <Link to="/organizers" onClick={takeToTop}>Organizers</Link>
            </div>
            <div className="footer_items footer_left_items">
              <Link to="/contacts" onClick={takeToTop}>Contact</Link>
            </div>
            <div className="footer_items footer_left_items">
              <Link to="/applyNow" onClick={takeToTop}>Apply Now</Link>
            </div>
            <div className="footer_items footer_left_items">
              <Link to="/blog" onClick={takeToTop}>Blog</Link>
            </div>
          </div>
          <div className="footer_Quick_link_section footer_section" >
            <div className="footer_title">
              Quick Links
            </div>
            <div className="footer_items footer_left_items">
              <Link to="/gallery" onClick={takeToTop}>Gallery</Link>
            </div>
            <div className="footer_items footer_left_items">
              <Link to="/funding" onClick={takeToTop}>Funding</Link>
            </div>
            <div className="footer_items footer_left_items">
              <Link to="/partnershipForm" onClick={takeToTop}>Partnership</Link>
            </div>
            <div className="footer_items footer_left_items">
              <Link to="/paymentInformation" onClick={takeToTop}>Payment Information</Link>
            </div>
            <div className="footer_items footer_left_items">
              <Link to="/visa" onClick={takeToTop}>Visa</Link>
            </div>
            {/* <div onClick={() => { navigate("/advisors"); takeToTop(); }}
  className="footer_items  footer_left_items">Advisors</div> */}
            <div className="footer_items footer_left_items">
              <Link to="/termsAndCondition" onClick={takeToTop}>Terms and Conditions</Link>
            </div>
          </div>
        </div>
        <div className="footer_vertical_line"></div>
        <div className="footer_right_div">
          <div className="footer_Contact_section footer_section">
            <div className="footer_title">
              Contact Us
            </div>
            <div className="footer_items footer_contact_items ">
              <WhatsAppIcon className="footer_icons" /> +1(302)208-7573
            </div>
            <div className="footer_items footer_contact_items " >
              <PhoneIcon className="footer_icons" /> +1(302)208-7573
            </div>
            <div className="footer_items footer_contact_items ">
              <PhoneIcon className="footer_icons" /> +1(302)803-4743
            </div>
            <div className="footer_items footer_contact_items ">
              <MailIcon className="footer_icons" /> info@gebootcamp.com
            </div>
            <div className="footer_items footer_contact_items ">
              <MailIcon className="footer_icons" />
              globalentrepreneurshipbootcamp@gmail.com
            </div>
            <div className="footer_items footer_contact_items ">
              <LocationOnIcon className="footer_icons" />
              407 E Ayre St #1156
              Wilmington, DE 19804
              United States
            </div>
          </div>
        </div>
      </div>
      <CopyRight />
    </div>
  );
};

export default Footer;
